<template>
  <v-dialog v-model="state.dialog" scrollable :persistent="true" width="488">
    <v-card class="modal">
      <v-card-title class="modal-title">{{ situationModalTitle }}</v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="pt-5">
          <p>
            Tem certeza que deseja
            {{ state.mode.label }}
            o <strong>{{ user.fullName }}</strong
            >?
          </p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          class="cancel-button black-3--text"
          color="#ffffff"
          @click="state.dialog = false"
          >Cancelar</v-btn
        >
        <v-btn
          type="button"
          @click="actionChangeSituation"
          color="secondary-color-1"
          class="next-step-button white--text"
          >{{ state.mode.label }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { UserSituation } from "@/modules/account/user/enum/userSituation.enum";

  import { mapActions } from "vuex";

  export default {
    props: {
      user: {
        required: true,
        type: Object
      },
      state: {
        required: true,
        type: Object
      }
    },
    data: () => ({
      dialogChangeUserSituation: false,
      situationModes: {
        [UserSituation.BLOCKED.description]: {
          label: "Ativar",
          postAction: "ativado",
          icon: "mdi-account-reactivate",
          situationId: UserSituation.ACTIVE.id
        },
        [UserSituation.ACTIVE.description]: {
          label: "Bloquear",
          postAction: "bloqueado",
          icon: "mdi-block-helper",
          situationId: UserSituation.BLOCKED.id
        }
      },
      situationModalTitle: ""
    }),
    mounted() {
      this.initialize();
    },
    watch: {
      "user.situation": function() {
        this.initialize();
      }
    },
    methods: {
      ...mapActions("user", ["changeSituation"]),
      ...mapActions(["toggleSnackbar"]),
      initialize() {
        if (!this.situationModes[this.user.situation]) {
          return (this.state.canChangeSituation = false);
        }
        this.state.canChangeSituation = true;
        this.state.mode = this.situationModes[this.user.situation];
        this.situationModalTitle = `${this.state.mode.label} funcionário`;
      },
      async actionChangeSituation() {
        try {
          const response = await this.changeSituation({
            id: this.user.id,
            data: {
              situationUser: this.state.mode.situationId
            }
          });

          this.$emit("success");
          this.toggleSnackbar({
            text:
              response?.message ??
              `Usuario(a) ${this.state.mode.postAction}(a)`,
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        } finally {
          this.state.dialog = false;
        }
      }
    }
  };
</script>
<style></style>
