var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"d-flex flex-column justify-space-between",attrs:{"fill-height":""}},[_c('div',[[_c('div',{staticClass:"row px-5 d-flex justify-end"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h2',{staticClass:"mt-2"},[_vm._v("Funcionários")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":Object.values(_vm.profileSelect),"item-text":"description","item-value":"id","outlined":"","color":"secondary-color-1","label":"Perfil"},on:{"change":function($event){return _vm.setFilter('roles', $event)}}})],1),_c('v-col',{attrs:{"cols":"9","sm":"8","md":"4"}},[_c('v-text-field',{attrs:{"solo":"","flat":"","full-width":"","color":"secondary-color-1","background-color":"white-1","placeholder":"Buscar funcionário","prepend-inner-icon":"mdi-magnify"},on:{"input":function($event){return _vm.setFilterDebounced('name', $event)}}})],1),_c('v-col',{attrs:{"cols":"3","sm":"4","md":"2"}},[_c('report-options',{attrs:{"fileName":'Funcionários',"header":_vm.headerReport,"body":_vm.employees}})],1)],1),_c('v-divider')],(!_vm.employees.length)?[_c('div',{staticClass:"mt-5"},[(!_vm.filterPristine)?_c('data-not-found',{attrs:{"title":"Funcionário não encontrado.","imgSrc":require('@/assets/user.svg')}}):_vm._e(),(_vm.filterPristine)?_c('data-not-found',{attrs:{"title":"Você ainda não cadastrou nenhum funcionário, deseja cadastrar?","btnLabel":"Cadastrar o primeiro funcionário","btnAction":_vm.goToAddUser,"imgSrc":require('@/assets/user.svg')}}):_vm._e()],1)]:_vm._e(),(_vm.employees.length)?[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.employees,"items-per-page":_vm.tableInfo.itemsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('user-photo',{staticClass:"mr-2",attrs:{"size":"sm","border":"thin"},model:{value:(item.photoHash),callback:function ($$v) {_vm.$set(item, "photoHash", $$v)},expression:"item.photoHash"}}),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.fullName))])],1)]}},{key:"item.cpf",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("VMask")(item.cpf,_vm.masks.cpf))+" ")]}},{key:"item.situation",fn:function(ref){
var item = ref.item;
return [_c('user-situation-chip',{attrs:{"text":item.situation}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('user-menu-item',{attrs:{"user":item,"canManageUser":_vm.canManageUser},on:{"updateData":_vm.updateData}})]}}],null,true)})]:_vm._e()],2),(_vm.employees.length)?_c('div',[_c('pagination',{attrs:{"currentPage":_vm.tableInfo.currentPage,"totalPages":_vm.tableInfo.totalPages,"itemsPerPage":_vm.tableInfo.itemsPerPage},on:{"currentPageChanged":function($event){return _vm.handleCurrentPageChange($event)},"itemsPerPageChanged":function($event){return _vm.handleItemsPerPageChange($event)}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }