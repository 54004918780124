<template>
  <div>
    <div class="px-5">
      <v-text-field
        solo
        flat
        full-width
        background-color="white-1"
        color="secondary-color-1"
        placeholder="Buscar"
        prepend-inner-icon="mdi-magnify"
        @input="debounceInput"
      ></v-text-field>
    </div>
    <v-divider></v-divider>
    <div v-if="users.length" class="data-container mx-7">
      <div v-for="user in users" :key="user.id">
        <v-checkbox
          v-model="selectedUsers"
          :disabled="disabledCheckbox(user)"
          dense
          :value="user"
          color="primary-color-2"
        >
          <template slot="label">
            <user-photo
              class="photo"
              v-model="user.photo"
              border="thin"
              size="sm"
            />
            <span class="name flex-grow-0 ml-3 black-1--text">{{
              user.fullName
            }}</span>
            <span class="flex-grow-1 ml-5">{{
              user.cpf | VMask(masks.cpf)
            }}</span>
          </template>
        </v-checkbox>
      </div>
      <infinite-loading spinner="waveDots" @infinite="loadMore">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
    <div v-if="!users.length" class="mt-5">
      <data-not-found
        title="Nenhum funcionário encontrado"
        :imgSrc="require('@/assets/user.svg')"
      />
    </div>
  </div>
</template>
<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";
  import InfiniteLoading from "vue-infinite-loading";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import { debounce } from "lodash";

  import { mapActions } from "vuex";

  export default {
    props: {
      value: {
        type: null
      },
      filters: {
        type: Object,
        required: false
      },
      canRemove: {
        type: Boolean,
        required: false,
        default: () => false
      },
      defaults: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    components: {
      DataNotFound,
      InfiniteLoading,
      UserPhoto
    },
    mixins: [MasksMixin],
    data: () => ({
      users: [],
      searchTerm: "",
      filterPristine: true,
      page: 1,
      limit: 10
    }),
    async mounted() {
      this.selectedUsers = [...this.defaults];
      this.users = await this.fetchUsers();
    },
    computed: {
      selectedUsers: {
        get() {
          return this.value;
        },
        set(newVal) {
          this.$emit("input", newVal);
        }
      }
    },
    methods: {
      ...mapActions("user", ["getUsers"]),
      getPayloadData() {
        const filters = this.filters ?? {};
        return {
          ...filters,
          name: this.searchTerm,
          page: this.page,
          limit: this.limit
        };
      },
      async fetchUsers() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getUsers(payload);
          return this.formatUsersData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },
      formatUsersData(data) {
        return data.map(user => {
          const userRole = user.userRoles[0];
          return {
            id: user.id,
            fullName: `${user.firstName} ${user.surname}`,
            cpf: user.cpf,
            photo: user.photo?.hash ?? null,
            role: userRole?.role?.id ?? null
          };
        });
      },
      async loadMore($state) {
        this.page = this.page + 1;
        const data = await this.fetchUsers();

        if (data.length) {
          this.users = [...this.users, ...data];
          $state.loaded();
        } else {
          $state.complete();
        }
      },
      debounceInput: debounce(async function(value) {
        this.filterPristine = false;
        this.searchTerm = value;
        this.page = 1;
        this.users = [];
        this.users = await this.fetchUsers();
      }, 800),
      disabledCheckbox(user) {
        return (
          this.defaults.some(selectedUser => user.id === selectedUser.id) &&
          !this.canRemove
        );
      }
    }
  };
</script>
<style lang="scss" scoped>
  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .photo {
    width: 32px;
    height: 32px;
  }

  .name {
    width: 250px;
  }
</style>
