<template>
  <div v-if="state.dialog">
    <v-dialog v-model="state.dialog" scrollable persistent width="594">
      <validation-observer>
        <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
          <v-card class="modal">
            <v-card-title class="modal-title"
              >Endereço - {{ state.crcName }}</v-card-title
            >
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <div class="mx-7 mt-5">
                <h3 class="mb-4">Endereço</h3>
                <address-form
                  :address="state.form.address"
                  :isRequired="true"
                />
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex flex-wrap justify-space-between">
              <v-btn
                class="mt-1 cancel-button black-3--text"
                color="#ffffff"
                :block="$isMobile"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                :disabled="invalid"
                :block="$isMobile"
                color="secondary-color-1"
                class="ma-0 mt-1 next-step-button white--text"
                >Confirmar e Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </div>
</template>
<script>
  import { ValidationObserver } from "vee-validate";
  import AddressForm from "@/modules/core/views/components/AddressForm.vue";

  export default {
    props: {
      state: {
        type: Object,
        required: true
      }
    },
    components: {
      ValidationObserver,
      AddressForm
    },
    methods: {
      submitForm() {
        this.state.dialog = false;
        this.$emit("submit", {
          ...this.state.form
        });
      }
    }
  };
</script>
<style lang="scss" scoped></style>
