<template>
  <v-container class="content">
    <title-header-action
      :title="crc.description"
      :hasActionPermission="userCanManageEmployees"
      btnLabel="Gerenciar Funcionários"
      :btnAction="goToManageEmployees"
    />
    <v-row>
      <v-col cols="12" lg="4">
        <v-card
          class="d-flex flex-column justify-space-between info-content mb-10"
        >
          <div>
            <v-card-title
              class="d-flex justify-space-between align-center pt-10"
            >
              <h2>Informações</h2>
              <pencil-btn v-if="userCanEdit" @action="editCrcInfo" />
            </v-card-title>
            <v-card-text class="mb-8">
              <!-- CNPJ -->
              <v-row class="ma-0 mt-2" align="center" justify="start">
                <div class="d-flex justify-start align-center">
                  <v-icon class="mr-5" size="18" left>mdi-receipt</v-icon>
                  <p class="ma-0" v-if="crc.cnpj">
                    {{ crc.cnpj | VMask(masks.cnpj) }}
                  </p>
                </div>
              </v-row>

              <!-- email -->
              <v-row class="ma-0 mt-2" align="center" justify="start">
                <div class="d-flex justify-start align-center">
                  <v-icon class="mr-5" size="18" left>mdi-email</v-icon>
                  <p class="ma-0">
                    {{ crc.email ? crc.email : "não informado" }}
                  </p>
                </div>
              </v-row>

              <!-- phone -->
              <v-row
                v-if="crc.phonesNumber"
                class="ma-0 mt-2"
                align="center"
                justify="start"
              >
                <div class="d-flex justify-start align-center">
                  <v-icon class="mr-5" size="18" left>mdi-phone</v-icon>
                  <p class="ma-0">{{ crc.phonesNumber }}</p>
                </div>
              </v-row>
            </v-card-text>
          </div>

          <div>
            <v-divider></v-divider>

            <v-card-title
              class="d-flex justify-space-between align-center pt-10"
            >
              <h3>Endereço</h3>
              <pencil-btn v-if="userCanEdit" @action="editCrcAddress" />
            </v-card-title>

            <v-card-text>
              <!-- address -->
              <div class="ma-0 mt-2">
                <p class="ma-0">{{ crc.fullAddress[0] }}</p>
                <p>{{ crc.fullAddress[1] }}</p>
              </div>
            </v-card-text>
          </div>
        </v-card>

        <v-card class="info-content mb-10">
          <v-card-title class="d-flex justify-space-between align-center pt-10">
            <h2>Gerente</h2>
            <pencil-btn
              v-if="userCanEdit && crc.userRoles && crc.userRoles.length"
              @action="openDialogAddCrcManagers"
            />
          </v-card-title>
          <v-card-text>
            <template v-if="crc.userRoles && !crc.userRoles.length">
              <v-layout column justify-center align-center>
                <div class="not-found-manager-content">
                  <div class="d-flex justify-center align-center">
                    <img
                      width="50"
                      class="mr-2"
                      src="@/assets/manager.svg"
                      alt="gerente não encontrado"
                    />
                    <p class="ma-0 black3--text">
                      Adicione um gerente a essa CRC
                    </p>
                  </div>
                  <div class="fill mt-5">
                    <btn-dashed
                      :btnAction="openDialogAddCrcManagers"
                      depressed
                      block
                      title="Adicionar Gerente"
                    />
                  </div>
                </div>
              </v-layout>
            </template>

            <div
              v-for="manager in crc ? crc.userRoles : []"
              :key="manager.id"
              class="mt-2"
            >
              <manager-list-item :manager="manager" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8">
        <div class="users-content">
          <v-card class="pt-5">
            <template v-if="crc.id">
              <view-employees :crc-id="crc.id" />
            </template>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <div v-if="crc.managers">
      <add-manager-dialog :state="editManagerState" @submit="saveManager" />
      <update-crc :state="updateCrcState" @submit="handleSubmitUpdateCrc" />
      <update-crc-address
        :state="updateAddressState"
        @submit="handleSubmitUpdateAddress"
      />
    </div>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";
  import ManagerListItem from "@/modules/crc/views/components/ManagersListItem";
  import ViewEmployees from "@/modules/crc/views/components/ViewEmployees";
  import PencilBtn from "@/modules/core/views/components/PencilBtn";
  import AddManagerDialog from "@/modules/crc/views/components/AddManagerDialog";
  import UpdateCrcAddress from "@/modules/crc/views/components/UpdateCrcAddress";
  import UpdateCrc from "@/modules/crc/views/components/UpdateCrc";

  import { profiles } from "@/modules/core/enums/profiles.enum";
  import { parseAddress } from "@/modules/core/helpers/addressHelpers";
  import BtnDashed from "@/modules/core/views/components/BtnDashed";

  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";

  export default {
    name: "ViewCRC",

    components: {
      TitleHeaderAction,
      ManagerListItem,
      ViewEmployees,
      PencilBtn,
      AddManagerDialog,
      UpdateCrc,
      UpdateCrcAddress,
      BtnDashed
    },

    mixins: [MasksMixin],

    data: () => ({
      crc: {
        id: null,
        cnpj: null,
        active: null,
        address: {
          id: null,
          zipCode: null,
          address: null,
          city: null,
          complement: null,
          neighborhood: null,
          number: null,
          state: {
            id: null,
            description: null,
            uf: null
          },
          phones: [
            {
              id: null,
              areaCode: null,
              phone: null,
              phoneType: null
            }
          ],
          userRoles: [
            {
              crc: null,
              role: null,
              user: {}
            }
          ]
        },

        fullAddress: [],

        phonesNumber: null
      },

      editManagerState: {
        dialog: false,
        canRemove: false,
        managers: []
      },

      updateCrcState: {
        dialog: false,
        crcName: "",
        form: {
          description: "",
          cnpj: "",
          email: "",
          phones: []
        }
      },

      updateAddressState: {
        dialog: false,
        crcName: "",
        form: {}
      },

      userCanEdit: true
    }),

    computed: {
      ...mapGetters({
        selectedUserCRC: "user/getSelectedUserCRC",
        user: "user/getUser",
        userIsAdmin: "user/getUserIsAdmin",
        userIsPublicRelationsDirector: "user/getUserIsPublicRelationsDirector",
        userIsManagerOperator: "user/getUserIsManagerOperator"
      }),
      userCanManageEmployees() {
        return (
          !this.userIsPublicRelationsDirector &&
          (this.userIsAdmin || this.userIsManagerOperator)
        );
      }
    },

    async mounted() {
      const profilesCanEdit = [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ];

      this.userCanEdit = profilesCanEdit.includes(this.user.role.id);
      this.editManagerState.canRemove = this.userIsAdmin;
      await this.initViewCRC();
    },

    methods: {
      ...mapActions("crc", ["getCRC", "updateCRC", "updateManagers"]),
      ...mapActions("user", ["getUserSelectedCRC"]),
      ...mapActions(["toggleSnackbar"]),

      openDialogAddCrcManagers() {
        this.editManagerState.managers = [...this.crc.managers];
        this.editManagerState.dialog = true;
      },

      closeDialogAddCrcManagers() {
        this.editManagerState.dialog = false;
      },

      async saveManager(managers) {
        const payload = {
          id: this.crc.id,
          data: {
            ...this.crc,
            managersIds: managers.map(manager => manager.id)
          }
        };

        try {
          const response = await this.updateManagers(payload);
          this.toggleSnackbar({
            text: response.message ?? "Gerentes atualizados com sucesso",
            type: "success"
          });
          this.initViewCRC();
          this.closeDialogAddCrcManagers();
        } catch (error) {
          this.toggleSnackbar({
            text:
              error?.data?.message ??
              "Não foi possível adicionar o gerente à CRC",
            type: "error"
          });
        }
      },

      async initViewCRC() {
        const { id } = this.$route.params;
        try {
          const { data } = await this.getCRC(id);
          this.crc = this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error.data.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },
      formatData(crc) {
        const phonesNumber = crc.phones
          .map(phone => this.parsePhone(phone))
          .join(", ");

        const fullAddress = parseAddress(crc.address);
        const managers = this.formatUserData(crc.userRoles);
        return { ...crc, managers, phonesNumber, fullAddress };
      },
      formatUserData(userRoles) {
        return userRoles.map(userRole => {
          const user = userRole.user;
          return {
            id: user.id,
            fullName: `${user.firstName} ${user.surname}`,
            cpf: user.cpf,
            photo: user.photo?.hash ?? null,
            role: userRole?.role?.id ?? null
          };
        });
      },
      goToManageEmployees() {
        this.$router.push({ name: "ManageEmployees" });
      },
      editCrcInfo() {
        this.updateCrcState.crcName = this.crc.description;

        const phones =
          this.crc?.phones?.map(phone => {
            return {
              phoneType: phone.phoneType,
              phoneNumber: `${phone.areaCode}${phone.phone}`
            };
          }) ?? [];

        this.updateCrcState.form = {
          description: this.crc.description,
          cnpj: this.crc.cnpj,
          email: this.crc.email,
          phones
        };
        this.updateCrcState.dialog = true;
      },
      editCrcAddress() {
        this.updateAddressState.crcName = this.crc.description;
        this.updateAddressState.form = {
          ...this.updateAddressState.form,
          address: { ...this.crc.address }
        };
        this.updateAddressState.dialog = true;
      },
      async handleSubmitUpdateCrc(updateState) {
        await this.updateCrc({
          ...updateState,
          phones: preparePhonesToSubmit(updateState.phones)
        });
      },
      async handleSubmitUpdateAddress(updateState) {
        await this.updateCrc(updateState);
      },
      async updateCrc(data) {
        try {
          const payload = this.formatUpdatePayload(data);
          const response = await this.updateCRC(payload);

          this.toggleSnackbar({
            text: response?.data?.message ?? "Registro alterado com sucesso",
            type: "success"
          });

          await this.initViewCRC();
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro inesperado",
            type: "error"
          });
        }
      },
      formatUpdatePayload(data) {
        if (!this.crc.id) {
          throw new Error("CRC Id required");
        }
        return {
          id: this.crc.id,
          data: { ...data }
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .content {
    .info-content {
      min-height: 250px;
      min-width: 300px;

      .not-found-manager-content {
        min-width: 295px;
        width: 60%;
      }
    }

    .users-content {
      height: 100%;
      min-width: 300px;

      .v-card {
        height: inherit;
      }
    }
  }
</style>
