<template>
  <v-layout fill-height class="d-flex flex-column justify-space-between">
    <div>
      <template
        ><div class="row px-5 d-flex justify-end">
          <v-col cols="12" md="3">
            <h2 class="mt-2">Funcionários</h2>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              :items="Object.values(profileSelect)"
              item-text="description"
              item-value="id"
              outlined
              @change="setFilter('roles', $event)"
              color="secondary-color-1"
              label="Perfil"
            ></v-select>
          </v-col>

          <v-col cols="9" sm="8" md="4">
            <v-text-field
              @input="setFilterDebounced('name', $event)"
              solo
              flat
              full-width
              color="secondary-color-1"
              background-color="white-1"
              placeholder="Buscar funcionário"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-col>

          <v-col cols="3" sm="4" md="2">
            <report-options
              :fileName="'Funcionários'"
              :header="headerReport"
              :body="employees"
            />
          </v-col>
        </div>
        <v-divider></v-divider
      ></template>
      <template v-if="!employees.length">
        <div class="mt-5">
          <data-not-found
            v-if="!filterPristine"
            title="Funcionário não encontrado."
            :imgSrc="require('@/assets/user.svg')"
          />
          <data-not-found
            v-if="filterPristine"
            title="Você ainda não cadastrou nenhum funcionário, deseja cadastrar?"
            btnLabel="Cadastrar o primeiro funcionário"
            :btnAction="goToAddUser"
            :imgSrc="require('@/assets/user.svg')"
          />
        </div>
      </template>
      <template v-if="employees.length">
        <v-data-table
          :headers="headers"
          :items="employees"
          :items-per-page="tableInfo.itemsPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.fullName`]="{ item }">
            <div class="d-flex align-center">
              <user-photo
                class="mr-2"
                v-model="item.photoHash"
                size="sm"
                border="thin"
              />
              <p class="ma-0">{{ item.fullName }}</p>
            </div>
          </template>
          <template v-slot:[`item.cpf`]="{ item }">
            {{ item.cpf | VMask(masks.cpf) }}
          </template>
          <template v-slot:[`item.situation`]="{ item }">
            <user-situation-chip :text="item.situation" />
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <user-menu-item
              :user="item"
              :canManageUser="canManageUser"
              @updateData="updateData"
            />
          </template>
        </v-data-table>
      </template>
    </div>
    <div v-if="employees.length">
      <pagination
        :currentPage="tableInfo.currentPage"
        :totalPages="tableInfo.totalPages"
        :itemsPerPage="tableInfo.itemsPerPage"
        @currentPageChanged="handleCurrentPageChange($event)"
        @itemsPerPageChanged="handleItemsPerPageChange($event)"
      />
    </div>
  </v-layout>
</template>
<script>
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import UserSituationChip from "@/modules/account/user/views/components/UserSituationChip";
  import UserMenuItem from "@/modules/account/user/views/components/UserMenuItem";
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";
  import Pagination from "@/modules/core/views/components/Pagination";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import { profiles } from "@/modules/core/enums/profiles.enum";
  import { commonProfiles } from "@/modules/core/enums/profiles.enum";

  import { mapActions, mapGetters } from "vuex";

  export default {
    props: {
      crcId: {
        type: Number,
        required: true
      }
    },
    mixins: [TableHandler, MasksMixin],
    components: {
      ReportOptions,
      DataNotFound,
      UserSituationChip,
      UserMenuItem,
      UserPhoto,
      Pagination
    },
    data: () => ({
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "fullName"
        },
        { text: "CPF", value: "cpf" },
        { text: "Perfil", value: "profile" },
        { text: "Telefone", value: "phone" },
        { text: "Situação", value: "situation" },
        { value: "id", sortable: false }
      ],
      headerReport: [
        {
          header: "Nome",
          dataKey: "fullName"
        },
        { header: "CPF", dataKey: "cpf" },
        { header: "Perfil", dataKey: "profile" },
        { header: "Telefone", dataKey: "phone" },
        { header: "Situação", dataKey: "situation" }
      ],

      employees: [],

      canManageUser: false
    }),

    async mounted() {
      this.initPermissions();
      this.initFilter("roles", null);
      this.initFilter("name", null);

      await this.fetchEmployees(this.crcId);
    },

    computed: {
      ...mapGetters("user", {
        user: "getUser"
      }),

      profileSelect() {
        const all = {
          All: {
            description: "Todos",
            id: ""
          }
        };

        return { ...all, ...commonProfiles };
      }
    },

    methods: {
      ...mapActions("crc", ["getUsersFromCRC"]),
      ...mapActions(["toggleSnackbar"]),

      initPermissions() {
        const usersCanManage = [
          profiles.PRESIDENT.id,
          profiles.DIRECTOR.id,
          profiles.MANAGER_OPERATING.id
        ];

        this.canManageUser = usersCanManage.includes(this.user.role.id);
      },

      async fetchEmployees(crcId) {
        try {
          const filters = this.getPayloadData();
          const { data, meta } = await this.getUsersFromCRC({
            id: crcId,
            data: filters
          });
          this.setTableInfo(meta);
          this.employees = this.formatEmployeesData(data);
        } catch (error) {
          this.toggleSnackbar({
            text:
              error.data?.message ??
              "Ocorreu um erro ao carregar funcionários, tente novamente",
            type: "error"
          });
        }
      },

      formatEmployeesData(employees) {
        return employees.map(employee => {
          const userRole = employee.userRoles[0];
          const phone = employee.phones?.length
            ? this.parsePhone(employee.phones[0])
            : "não informado";
          return {
            id: employee.id,
            fullName: `${employee.firstName} ${employee.surname}`,
            photoHash: employee.photo?.hash ?? null,
            cpf: employee.cpf,
            profile: userRole?.role?.description ?? "não informado",
            phone,
            situation: employee.situationUser.description,
            email: employee.email
          };
        });
      },

      async updateData() {
        await this.fetchEmployees(this.crcId);
      },

      goToAddUser() {
        this.$router.push({ name: "AddUser" });
      }
    }
  };
</script>
<style></style>
