<template>
  <v-dialog v-model="state.dialog" :persistent="true" width="536">
    <v-card class="modal">
      <v-card-title class="modal-title">Reenviar e-mail</v-card-title>
      <v-divider></v-divider>

      <validation-observer>
        <v-form
          @submit.prevent="submit"
          slot-scope="{ invalid }"
          class="form-resend-email"
        >
          <v-card-text>
            <div class="pt-5">
              <p>
                Ao reenviar e-mail o funcionário poderá fazer sua autenticação e
                cadastrar sua senha para ter acesso a plataforma.
              </p>
              <div>
                <field
                  v-if="isUpdateEmail"
                  v-model="user.email"
                  type="email"
                  label="E-mail"
                  name="E-mail"
                  :isRequired="true"
                />
                <div
                  class="d-flex justify-space-between mt-5"
                  v-if="!isUpdateEmail"
                >
                  <span>{{ user.email }}</span>
                  <v-icon
                    @click="isUpdateEmail = true"
                    class="icons-isUpdateEmail"
                    >mdi-pencil</v-icon
                  >
                </div>
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="d-flex justify-space-between">
            <v-btn
              class="cancel-button black-3--text"
              color="#FFF"
              @click="cancelAction"
              >{{ cancelBtnLabel }}</v-btn
            >
            <v-btn
              type="submit"
              :disabled="invalid && isUpdateEmail"
              color="secondary-color-1"
              class="next-step-button white--text"
              >{{ confirmBtnLabel }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import { UserSituation } from "@/modules/account/user/enum/userSituation.enum";
  import { ValidationObserver } from "vee-validate";
  import Field from "@/modules/core/views/components/Field";

  import { mapActions } from "vuex";

  export default {
    props: {
      user: {
        type: Object,
        required: true
      },
      state: {
        type: Object,
        required: true
      }
    },
    components: {
      ValidationObserver,
      Field
    },
    data: () => ({
      isUpdateEmail: false,
      backupEmail: null
    }),
    mounted() {
      this.state.canResendEmail =
        this.user.situation === UserSituation.PENDING.description;
      this.backupEmail = this.user.email;
    },
    computed: {
      cancelBtnLabel() {
        return this.isUpdateEmail ? "Voltar" : "Cancelar";
      },
      confirmBtnLabel() {
        return this.isUpdateEmail ? "Salvar e Enviar" : "Confirmar e Enviar";
      }
    },
    methods: {
      ...mapActions("user", ["resendEmail"]),
      ...mapActions(["toggleSnackbar"]),
      cancelAction() {
        if (this.isUpdateEmail) {
          this.user.email = this.backupEmail;
          return (this.isUpdateEmail = false);
        }
        this.state.dialog = false;
      },
      async submit() {
        try {
          const response = await this.resendEmail({
            data: {
              email: this.user.email
            },
            id: this.user.id
          });

          this.state.dialog = false;

          this.$emit("success");

          this.toggleSnackbar({
            text: response?.data?.message ?? "E-mail reenviado com sucesso",
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  span {
    color: $black-1;
    opacity: 0.9;
    font-size: 20px;
    font-weight: 500;
  }
</style>
