<template>
  <div v-if="state.dialog">
    <v-dialog v-model="state.dialog" scrollable :persistent="true" width="594">
      <v-card class="modal">
        <v-card-title class="black-1--text"
          >Adicione um gerente a uma CRC</v-card-title
        >

        <v-card-text class="pa-0">
          <div class="chip-container mx-7 mb-5">
            <p>Selecionados</p>
            <user-chip-list>
              <user-chip
                v-for="user in selectedUsers"
                :user="user"
                :key="user.id"
                :canClose="canRemoveChip(user)"
                @close="removeManager(user)"
                class="ma-2"
              />
            </user-chip-list>
          </div>
          <select-user
            v-model="selectedUsers"
            :filters="filters"
            :canRemove="state.canRemove"
            :defaults="state.managers"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            class="mt-1 cancel-button black-3--text"
            color="#ffffff"
            :block="$isMobile"
            @click="state.dialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            type="button"
            color="secondary-color-1"
            :block="$isMobile"
            class="ma-0 mt-1 next-step-button white--text"
            @click="submitForm"
            >Adicionar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import SelectUser from "@/modules/account/user/views/components/SelectUser";
  import UserChipList from "@/modules/account/user/views/components/UserChipList";
  import UserChip from "@/modules/account/user/views/components/UserChip";

  import { profiles } from "@/modules/core/enums/profiles.enum";

  export default {
    props: {
      state: {
        type: Object,
        required: true
      }
    },
    components: {
      SelectUser,
      UserChipList,
      UserChip
    },
    data: () => ({
      selectedUsers: []
    }),
    mounted() {},
    computed: {
      filters() {
        const inputFilters = this.state?.filters ?? {};
        return {
          roles: profiles.MANAGER_OPERATING.id,
          ...inputFilters
        };
      }
    },
    methods: {
      removeManager(user) {
        this.selectedUsers = this.selectedUsers.filter(
          selectedUser => selectedUser.id !== user.id
        );
      },
      submitForm() {
        this.state.dialog = false;
        this.$emit("submit", this.selectedUsers);
      },
      canRemoveChip(user) {
        return (
          !this.state.managers.some(manager => manager.id === user.id) ||
          this.state.canRemove
        );
      }
    }
  };
</script>
<style lang="scss" scoped>
  .chip-container {
    min-height: 150px;
  }
</style>
