<template>
  <v-layout class="d-flex">
    <div class="profile-img-container d-flex justify-center align-center">
      <user-photo size="md" border="thin" v-model="photo" />
    </div>
    <div class="d-flex flex-column justify-center ml-3">
      <h3>{{ user.fullName }}</h3>
      <p class="ma-0 pt-1 word-break">
        {{ user.email }}
      </p>
      <p v-for="(phone, index) in user.phones" :key="index" class="ma-0 pt-1">
        {{ phone }}
      </p>
    </div>
  </v-layout>
</template>
<script>
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  export default {
    props: {
      manager: {
        type: Object,
        required: true
      }
    },
    components: {
      UserPhoto
    },
    mixins: [MasksMixin],
    data: () => ({
      photo: null
    }),
    mounted() {
      this.initializePhoto();
    },
    computed: {
      user() {
        return {
          fullName: `${this.manager.user.firstName} ${this.manager.user.surname}`,
          email: this.manager.user.email,
          phones:
            this.manager.user?.phones?.map(phone => this.parsePhone(phone)) ??
            []
        };
      }
    },
    methods: {
      initializePhoto() {
        this.photo = this.manager?.user?.photo?.hash;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .profile-img-container {
    min-width: 100px;
  }
</style>
